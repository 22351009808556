<template>
    <div v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" :class="pageMeta.component_name.toLowerCase()+'-page'" class="rec-data-div">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient :client_id="client_id" @getClient="getClient"/>
                        <br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button v-if="signature_ids.length && original_signature_ids.length"
                        type="button"
                        @click="signature_ids=[]">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right"
                        type="button"
                        @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div v-if="original_signature_ids.length && !signature_ids.length" class="dark-text header-w-bg addendum">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15"/>

                <div class="dark-text header-w-bg bottom-20">Client Safety Plan</div>
                <!-- put generated code BELOW here -->
                <div class="avoid-break">
                    <h4 class="bold">Step 1: Warning signs (thoughts, images, mood, situation, behavior) that a crisis may be
                        developing.</h4>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.warning__sign_1"
                        label="Warning  Sign 1"
                        type='text'/>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.warning__sign_2"
                        label="Warning Sign 2"
                        type='text'/>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.warning__sign_3"
                        label="Warning Sign 3"
                        type='text'/>
                </div>
                <div class="avoid-break">
                    <h4 class="bold">Step 2: Internal coping strategies - Things I can do to take my mind off my problems without
                        contacting another person (relaxation technique, physical activity)</h4>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.coping_strat_1"
                        label="Coping Strategy 1"
                        type='text'/>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.coping_strat_2"
                        label="Coping Strategy 2"
                        type='text'/>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.coping_strat_3"
                        label="Coping Strategy 3"
                        type='text'/>
                </div>
                <div class="avoid-break">
                    <h4 class="bold">Step 3: People and social settings (places) that provide distraction</h4>
                    <div class="flex colx2 space-between bottom-15">
                        <Input
                            v-model="rec.person_one_name"
                            label="Name of Person 1"
                            type='text'/>
                        <Input
                            v-model="rec.person_one_phone"
                            label="Phone Number of Person 1" rules="phone"
                            type='text' name="Phone Number of Person 1"/>
                    </div>
                    <div class="flex colx2 space-between bottom-15">
                        <Input
                            v-model="rec.person_two_name"
                            label="Name of Person 2"
                            type='text'/>
                        <Input
                            v-model="rec.person_two_phone"
                            label="Phone Number of Person 2" rules="phone"
                            type='text'  name="Phone Number of Person 2"/>
                    </div>
                    <Input
                        class="bottom-15 block"
                        v-model="rec.location_1"
                        label="Place 1"
                        type='text'/>
                    <Input
                        v-model="rec.location_2"
                        label="Place 2"
                        type='text'/>
                </div>
                <div class="avoid-break">
                    <h4 class="bold">Step 4: People whom I can ask for help:</h4>
                    <div class="flex colx2 space-between bottom-15">
                        <Input
                            v-model="rec.helper_one_name_"
                            label="Name of Person 1"
                            type='text'/>
                        <Input
                            v-model="rec.helper_one_phone"
                            label="Phone Number of Person 1" rules="phone"
                            type='text'
                            name="Phone Number of Person 1"/>
                    </div>
                    <div class="flex colx2 space-between bottom-15">
                        <Input
                            v-model="rec.helper_two_name"
                            label="Name of Person 2"
                            type='text'/>
                        <Input
                            v-model="rec.helper_two_phone"
                            label="Phone Number of Person 2"
                            rules="phone"
                            type='text'
                            name="Phone Number of Person 2"/>
                    </div>
                    <div class="flex colx2 space-between bottom-15">
                        <Input
                            v-model="rec.helper_3_name"
                            label="Name of Person 3"
                            type='text'/>
                        <Input
                            v-model="rec.helper_three_phone_"
                            label="Phone Number of Person 3"
                            rules="phone"
                            type='text'
                            name="Phone Number of Person 3"/>
                    </div>
                </div>
                <div class="avoid-break">
                    <h4 class="bold">Step 5: Professionals or agencies I can contact during a crisis:</h4>
                    <div class="flex bottom space-between bottom-15">
                        <Input
                            class="flex-2 right-15"
                            v-model="rec.clincian_one_name"
                            label="Name of Clincian 1"
                            type='text'/>
                        <Input
                            class="flex-1 right-15"
                            v-model="rec.clinician_one_phone"
                            label="Phone Number of Clinician 1"
                            rules="phone"
                            type='text'
                            name="Phone Number of Clinician 1"/>
                        <Input
                            class="flex-1"
                            v-model="rec.clinician_one_emerge"
                            label="Pager or Emergency Contact of Clinician 1"
                            name="Emergency Contact of Clinician 1"
                            rules="phone"
                            type='text'/>
                    </div>
                    <div class="flex bottom space-between bottom-15">
                        <Input
                            class="flex-2 right-15"
                            v-model="rec.clincian_two_name"
                            label="Name of Clinician 2"
                            type='text'/>
                        <Input
                            class="flex-1 right-15"
                            v-model="rec.clinician_two_phone"
                            label="Phone Number of Clinician 2"
                            rules="phone"
                            type='text'
                            name="Phone Number of Clinician 2"/>
                        <Input
                            class="flex-1"
                            v-model="rec.clinician_two_emerge"
                            label="Pager of Emergency Contact of Clinician 2"
                            name="Emergency Contact of Clinician 2"
                            rules="phone"
                            type='text'/>
                    </div>
                    <div class="flex colx2 space-between bottom-15">
                        <Input
                            v-model="rec.local_urgent_care_se"
                            label="Local Urgent Care Services"
                            type='text'/>
                        <Input
                            v-model="rec.local_urgent_care_ph"
                            label="Local Urgent Care Services Phone Number"
                            type='text'/>
                    </div>
                    <Input
                        class="bottom-15"
                        v-model="rec.local_urgent_care_ad"
                        label="Local Urgent Care Services Address"
                        type='text'/>
                    <p>Suicide Prevention Lifeline Phone: <a href="tel:18002738255">1-800-273-TALK (8255)</a></p>
                </div>

                <div class="avoid-break">
                    <h4 class="bold">Step 6: Making the environment safe</h4>
                    <Input
                        class="block bottom-15"
                        v-model="rec.safe_env_1"
                        label="Option 1"
                        type='text'/>
                    <Input
                        class="block bottom-15"
                        v-model="rec.safe_env_2"
                        label="Option 2"
                        type='text'/>
                    <Input
                        v-model="rec.live_for"
                        label="The one thing that is most important to me and worth living for is:"
                        type='text'
                        class="bold dark-text"/>
                </div>

                <!-- put generated code ABOVE here -->

                <div v-if="!signature_ids.length" class="align-right">
                    <button v-if="original_signature_ids.length && !signature_ids.length"
                            class="align-right inline-block secondary right-15"
                            type="button"
                            @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton :canedit="isEditable" class="inline-block" @click="updateRecord()"/>

                </div>
                <FormSignature v-if="signature_ids.length > 0" ref="form_signatures" :signature_ids="signature_ids"
                               :user_id="user_id" @getSignatures="getSignatures"/>
                <FormSignatureButton
                    :fileName="pageMeta.component_name"
                    :original_rec="original_rec"
                    :rec="rec"
                    :record_id="record_id"
                    :save="updateRecord"
                    :signature_ids="signature_ids"
                    :signatures_info="signatures_info"
                    @fail="fail2sign"
                    @resetView="reloadData"
                    @showModal="showPdfModal"
                />
            </form>
            <VIfWorkableModal
                v-if="showCreatePdfModal"
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
            >
                <SignConvertPdfModal/>
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
import FormClient from '@/components/formpieces/FormClient';
import FormCompany from '@/components/formpieces/FormCompany.vue';
import FormSignature from '@/components/formpieces/FormSignatureNew';
import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
import {dryalex} from '@/mixins/dryalex';
import {merge} from 'lodash';

export default {
    name: 'ClientSafetyPlan',
    props: {
        record_id: {
            type: [String, Number],
            required: false,
            default: 0
        }
    },
    components: {FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal},
    data() {
        return {
            loading: 1,
            updating: 0,
            isEditable: 1,
            user_id: 0,
            client_id: 0,
            page_id: 0,
            rec: {},
            original_rec: {},
            signature_ids: [],
            original_signature_ids: [],
            showCreatePdfModal: false,
            api_signs_needed: null,
            signatures_info: null,
            client: {},
            company: {},
            rows: [],
            level_id: 0,
            level: 'company',
            options: [],
            this_record_id: 0,
            router_push: 0,
        };
    },
    methods: {
        async fail2sign() {
            this.showPdfModal(false)
        },
        async reloadData(signature) {
            this.showCreatePdfModal = false;
            this.signature_ids.push(signature)
        },
        showPdfModal(bool) {
            this.showCreatePdfModal = bool;
        },
        getClient(data) {
            this.client = data;
        },
        getCompany(data) {
            this.company = data;
        },
        //signatures that come from the FormSignatures component
        getSignatures(data) {
            this.signatures_info = data ? data : [];
        },
        async updateRecord(e) {
            if (!this.updating) {
                this.updating = 1
                try {
                    e.preventDefault()
                } catch (error) {/**/
                }

                let xthis = await dryalex.form_data_update_record(this, e)
                merge(this, xthis)
                if (this.router_push) {
                    this.router_push = 1
                    this.$forceUpdate()
                }
                this.original_signature_ids = this.signature_ids
            }
        },
        async init() {
            let xthis = await dryalex.form_data_init(this)
            merge(this, xthis)
            this.loading = 0
        },
    },
    async created() {
        await this.init();
    },
    watch: {
        showCreatePdfModal(newVal) {
            if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
            if (newVal == false) {
                this.closeModalOverlay();
            }
        }
    }
};
</script>
